
export function manageArr(arr) {
    const newArr = []
    for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        obj.key = i
        obj.value = arr[i].name
        obj.text = arr[i].name
        obj.disabled = arr[i].hasInitialStoreTransaction
        delete obj.hasInitialStoreTransaction
        newArr.push(obj)
    }
    return newArr
}
