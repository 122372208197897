import {
    STORES_LIST_FETCHED,
    SHOPPING_HUB_FETCHED
} from '../types'

const initialState = {
    storeList: [],
    currency: null,
    claimMinimumAmount: null
}

export default function shoppingHubReducer(state = initialState, action) {
    switch (action.type) {
        case STORES_LIST_FETCHED:
            return { ...state, storeList: action.payload }
        case SHOPPING_HUB_FETCHED:
            return { ...state, currency: action.payload.currency, claimMinimumAmount: action.payload.claimMinimumAmount }
        default: return state
    }
}
