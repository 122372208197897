import axios from 'axios'

export default {
    shoppingHub: {
        fetchStoresList: async (externalId) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/customer/stores/all`, {
                    headers: { externalId }
                })
                const { data: stores } = response
                return stores
            } catch (error) {
                throw error
            }
        },
        fetchShoppingHub: async (customerExternalId) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_TC_HOST}/shoppingHubs?customerExternalId=${customerExternalId}`)
                return response.data
            } catch (error) {
                throw error
            }
        },
    },
    customer: {
        postTransactionComplaint: async (data) => {
            try {
                const { data: response } = await axios.post(`${process.env.REACT_APP_TC_HOST}/customer/transactions/complaint`, data)
                return response
            } catch (error) {
                throw error
            }
        },
    }
}
