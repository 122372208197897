import React from 'react'
import { connect } from 'react-redux'
import { Container, Message } from 'semantic-ui-react'
import { I18n } from 'react-i18next'
import TransactionComplaintForm from './components/TransactionComplaintForm'
import { fetchQueryParams } from '../../actions/customerAction'


class Home extends React.Component {
    state = { showForm: false }

    componentDidMount() {
        if (document.location.search) {
            const { props } = this
            props.fetchQueryParams(document.location)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customer.cid !== null) {
            this.setState({ showForm: true })
        }
    }

    render() {
        const { showForm } = this.state
        return (
            <section>
                <Container text>
                    {
                        showForm ? <TransactionComplaintForm /> : (
                            <I18n ns="translations">
                                {
                                    t => (
                                        <Message
                                            className="no-param-error-message"
                                            content={t('home.errorMessages.customerNotFound.text')}

                                        />
                                    )
                                }
                            </I18n>
                        )
                    }

                </Container>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        customer: state.customer
    }
}
export default connect(mapStateToProps, {
    fetchQueryParams
})(Home)
