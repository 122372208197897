import React from 'react'

import Home from './pages/Home'
import './App.css'


const app = () => (
    <div className="App">
        <Home />
    </div>
)

export default app
