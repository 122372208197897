import api from '../services/api'
import { STORES_LIST_FETCHED, SHOPPING_HUB_FETCHED } from '../types'

// Action Creators
export const storesListFetched = storeList => ({
    type: STORES_LIST_FETCHED,
    payload: storeList
})

export const shoppingHubFetched = shoppingHub => ({
    type: SHOPPING_HUB_FETCHED,
    payload: shoppingHub
})



export const fetchStoresList = externalId => async (dispatch) => {
    try {
        const storeList = await api.shoppingHub.fetchStoresList(externalId)
        return dispatch(storesListFetched(storeList))
    } catch (err) {
        throw err
    }
}

export const fetchShoppingHub = customerExternalId => async (dispatch) => {
    try {
        const shoppingHub = await api.shoppingHub.fetchShoppingHub(customerExternalId)
        return dispatch(shoppingHubFetched(shoppingHub))
    } catch (err) {
        throw err
    }
}

