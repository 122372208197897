import React from 'react'
import { connect } from 'react-redux'
import { SingleDatePicker } from 'react-dates'
import { I18n } from 'react-i18next'
import moment from 'moment'
import { Header, Form, Icon, Message, Loader, Input } from 'semantic-ui-react'
import getSymbolFromCurrency from 'currency-symbol-map'
import { fetchStoresList, fetchShoppingHub } from '../../../../actions/shoppingHubAction'
import { sendTransactionComplaint } from '../../../../actions/customerAction'
import 'react-dates/initialize'
import 'moment/min/locales' // Load all languages from moment
import './index.css'
import 'react-dates/lib/css/_datepicker.css'
import { manageArr } from '../../../../services/utils/arrayUtils'
import i18n from '../../../../i18n'


class TransactionComplaintForm extends React.Component {
    state = {
        transactionDate: null,
        storesList: [],
        storeName: '',
        amount: '',
        additionalFeedback: '',
        storeId: '',
        claimResponseCode: '',
        errors: {},
        focused: false,
        externalId: '',
        loading: true
    }

    async componentWillMount() {
        try {
            await Promise.all([
                this.props.fetchStoresList(this.props.cid),
                this.props.fetchShoppingHub(this.props.cid)
            ])
            const storesList = manageArr(this.props.storeList).sort((a, b) => a.name.localeCompare(b.name))
            if (i18n && i18n.language) {
                let dateFormat
                const twoLetterFormat = i18n.language.replace(/-.*/, '') // change from 'fr-FR' to 'fr'
                switch (twoLetterFormat) {
                case 'ca':
                case 'es':
                case 'fr':
                    dateFormat = 'DD/MM/YYYY'
                    break
                case 'sv':
                    dateFormat = 'YYYY/MM/DD'
                    break
                case 'pl':
                    dateFormat = 'DD/MM/YYYY'
                    break
                case 'en':
                    dateFormat = 'MM/DD/YYYY'
                    break
                default:
                    dateFormat = 'MM/DD/YYYY'
                    break
                }
                this.setState({ dateFormat })
            }

            return this.setState({ storesList, externalId: this.props.cid, loading: false, errors: {}, currency: this.props.currency })
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { code: 'serverError' }, loading: false })
            }
            const { code } = err.response.data
            if (code === 'customerNotFound') {
                return this.setState({ errors: { code: 'customerNotFound' }, loading: false })
            }
            return 1
        }
    }


    handleChange = (e, { name, value }) => {
        const { storesList } = this.state
        storesList.forEach((store) => {
            if (value === store.name) {
                this.setState({
                    storeId: store.id,
                })
            }
        })
        return this.setState({ [name]: value })
    }

    displayMinAmountForReward = (currency) => {
        const EUR_MIN_AMOUNT = 10
        const SEK_MIN_AMOUNT = 100
        const PLN_MIN_AMOUNT = 30
        const CZK_MIN_AMOUNT = 500

        let minAmountText
        switch (currency) {
        case 'EUR':
            minAmountText = `${EUR_MIN_AMOUNT} €`
            break
        case 'SEK':
            minAmountText = `${SEK_MIN_AMOUNT} kr`
            break
        case 'PLN':
            minAmountText = `${PLN_MIN_AMOUNT} zł`
            break
        case 'CZK':
            minAmountText = `${CZK_MIN_AMOUNT} Kč`
            break
        default:
            minAmountText = `${EUR_MIN_AMOUNT} €`
            break
        }
        return minAmountText
    }


    handleSubmit = async () => {
        try {
            const { state, props } = this
            this.setState({
                isLoadingBtn: true
            })
            await props.sendTransactionComplaint({
                amount: parseFloat(state.amount),
                transactionDate: state.transactionDate.format('YYYY-MM-DD'),
                currency: props.currency,
                storeId: state.storeId,
                additionalFeedback: state.additionalFeedback,
                externalId: state.externalId
            })
            return this.setState({ claimResponseCode: this.props.claimResponse.code, errors: {}, loading: false, isLoadingBtn: false })
        } catch (err) {
            if (!err.response) { // network error
                return this.setState({ errors: { code: 'serverError' }, loading: false })
            }
            return this.setState({ errors: { code: err.response.data.code }, loading: false })
        }
    }

    render() {
        const { storesList, transactionDate, amount, storeName, claimResponseCode, errors, focused, loading, isLoadingBtn } = this.state
        const isEnabled = transactionDate !== null && !!amount && !!storeName && !isLoadingBtn
        const hasError = Object.keys(errors).length
        let content

        if (claimResponseCode) {
            content = (
                <I18n ns="translations">
                    {
                        t => (
                            <Message
                                positive
                                size="mini"
                            >
                                {claimResponseCode === 'pending' && (
                                    <p>
                                        {t('home.transactionComplaintForm.pendingMessage.text')}
                                    </p>
                                )}
                                {claimResponseCode === 'insufficientAmount' && (
                                    <p>
                                        {
                                            t('home.transactionComplaintForm.insufficientAmount.text', { insufficientAmount: `${this.props.claimMinimumAmount} ${getSymbolFromCurrency(this.props.currency)}` })
                                        }
                                    </p>
                                )}
                                {claimResponseCode === 'transactionDateBeforeRegistration' && (
                                    <p>
                                        {t('home.transactionComplaintForm.transactionDateBeforeRegistration.text')}
                                    </p>
                                )}
                                {claimResponseCode === 'tooRecentTransaction' && (
                                    <p>
                                        {t('home.transactionComplaintForm.tooRecentTransaction.text')}
                                    </p>
                                )}
                                {claimResponseCode === 'validated' && (
                                    <p>
                                        {t('home.transactionComplaintForm.successMessage.text')}
                                    </p>
                                )}
                                {claimResponseCode === 'duplicate' && (
                                    <p>
                                        {t('home.transactionComplaintForm.duplicate.text')}
                                    </p>
                                )}
                                {claimResponseCode === 'already-validated' && (
                                    <p>
                                        {t('home.transactionComplaintForm.alreadyValidated.text')}
                                    </p>
                                )}
                            </Message>
                        )}
                </I18n>
            )
        } else if (hasError) {
            const { code } = errors
            content = (
                <I18n ns="translations">
                    {t => (
                        <Message negative size="tiny">
                            <p>
                                {code === 'customerNotFound' && (
                                    <p>
                                        {t('home.errorMessages.customerNotFound.text')}
                                    </p>
                                )}
                                {code === 'transactionsNotFound' && (
                                    <p>
                                        {t('home.errorMessages.transactionsNotFound.text')}
                                    </p>
                                )}
                                {code === 'serverError' && (
                                    <p>
                                        {t('home.errorMessages.serverError.text')}
                                    </p>
                                )}
                            </p>
                        </Message>
                    )}
                </I18n>
            )
        } else {
            content = (
                <I18n ns="translations">
                    {
                        t => (
                            <div>
                                <Message
                                    size="mini"
                                    info
                                    list={
                                        [
                                            t('home.header.info', { minAmountText: `${this.props.claimMinimumAmount} ${getSymbolFromCurrency(this.props.currency)}` }),
                                            t('home.header.warn')
                                        ]
                                    }
                                />
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group widths="equal">
                                        <Form.Select
                                            name="storeName"
                                            value={storeName}
                                            onChange={this.handleChange}
                                            selection
                                            label={t('home.transactionComplaintForm.store.label')}
                                            placeholder={t('home.transactionComplaintForm.store.placeholder')}
                                            search
                                            options={storesList}
                                            required
                                        />
                                        <div className="required field">
                                            <p className="label-date-picker">
                                                {t('home.transactionComplaintForm.transactionDate.label')}
                                                <span>
                                                    {t('home.transactionComplaintForm.transactionDate.required')}
                                                </span>
                                            </p>
                                            <SingleDatePicker
                                                numberOfMonths={1}
                                                readOnly
                                                locale={moment.locale(i18n.language)}
                                                orientation="horizontal"
                                                hideKeyboardShortcutsPanel
                                                placeholder={t('home.transactionComplaintForm.transactionDate.placeholder')}
                                                date={transactionDate} // momentPropTypes.momentObj or null
                                                onDateChange={date => this.setState({ transactionDate: date })} // PropTypes.func.isRequired
                                                focused={focused} // PropTypes.bool
                                                isOutsideRange={() => false}
                                                displayFormat={this.state.dateFormat}
                                                onFocusChange={(el) => {
                                                    this.setState({ focused: el.focused })
                                                }} // PropTypes.func.isRequired
                                                id="transactionDate" // PropTypes.string.isRequired,
                                            />

                                        </div>
                                        <div className="required field">
                                            <Form.Field>
                                                <label>{t('home.transactionComplaintForm.amount.label')}</label>
                                                <Input
                                                    step="0.01"
                                                    name="amount"
                                                    type="number"
                                                    placeholder={t('home.transactionComplaintForm.amount.placeholder')}
                                                    required
                                                    labelPosition="right"
                                                    label={{ basic: true, content: (getSymbolFromCurrency(this.state.currency) || '') }}
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Field>
                                        </div>


                                    </Form.Group>
                                    <Form.TextArea
                                        maxLength="255"
                                        autoHeight
                                        onChange={this.handleChange}
                                        style={{ minHeight: 100 }}
                                        label={t('home.transactionComplaintForm.additionalFeedback.label')}
                                        name="additionalFeedback"
                                        placeholder={t('home.transactionComplaintForm.additionalFeedback.placeholder')}
                                    />
                                    <Form.Button color="blue" loading={this.state.isLoadingBtn} disabled={!isEnabled}>
                                        {t('home.transactionComplaintForm.submitButton')}
                                    </Form.Button>
                                </Form>
                            </div>
                        )
                    }
                </I18n>
            )
        }
        return (
            <I18n ns="translations">
                {
                    t => (
                        <article>
                            <Header as="h2" icon textAlign="center" className="form-header">
                                <Icon name="users" circular />
                                <Header.Content>
                                    {t('home.header.h2')}
                                </Header.Content>
                            </Header>
                            {loading ? <Loader active inline="centered" /> : content}
                        </article>
                    )
                }
            </I18n>
        )
    }
}

const mapStateToProps = ({ shoppingHub, customer }) => ({
    storeList: shoppingHub.storeList,
    claimResponse: customer.claimResponse,
    currency: shoppingHub.currency,
    claimMinimumAmount: shoppingHub.claimMinimumAmount,
    cid: customer.cid,

})

export default connect(mapStateToProps, {
    fetchStoresList,
    fetchShoppingHub,
    sendTransactionComplaint
})(TransactionComplaintForm)
