import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'airbnb-browser-shims';
import Rollbar from 'rollbar'
import { I18nextProvider } from 'react-i18next'
import 'react-dates/initialize'
import configureStore from './store'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import 'semantic-ui-css/semantic.min.css'
import i18n from './i18n' // initialized i18next instance

// Rollbar settings
new Rollbar({
    accessToken: '020e55a5fb2144f5a7cf64319d035b84',
    handleUncaughtExceptions: true,
    handleUnhandledRejections: true,
    enabled: process.env.NODE_ENV !== 'development',
    payload: {
        environment: process.env.REACT_APP_TC_HOST === 'https://api-test.transactionconnect.com' ? 'staging' : process.env.NODE_ENV, // Set dynamic environment
        autoInstrument: true,
        client: {
            javascript: {
                source_map_enabled: true,
                guess_uncaught_frames: true,
                code_version: '98c099d618a19336357a048987cd828494ccb4a8', // git SHA of the current revision
            }
        },
        server: {
            host: process.env.REACT_APP_TC_HOST
        }
    }
})


ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={configureStore()}>
            <App />
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
)
registerServiceWorker()
