import URI from 'urijs'

import {
    TRANSACTION_COMPLAINT_SENT, QUERY_PARAMS_FETCHED
} from '../types'

const initialState = {
    cid: null,
    lng: null
}

export default function customerReducer(state = initialState, action) {
    switch (action.type) {
        case QUERY_PARAMS_FETCHED: {
            const queryString = URI(action.payload).query(true)
            return { ...state, cid: queryString.cid, lng: queryString.lng }
        }
        case TRANSACTION_COMPLAINT_SENT:
            return { ...state, claimResponse: action.payload }
        default: return state
    }
}
