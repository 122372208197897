import api from '../services/api'
import { TRANSACTION_COMPLAINT_SENT, QUERY_PARAMS_FETCHED } from '../types'


// Action Creators
export const transactionComplaintSent = claimResponse => ({
    type: TRANSACTION_COMPLAINT_SENT,
    payload: claimResponse
})


// Action Creators
export const queryParamFetched = queryParams => ({
    type: QUERY_PARAMS_FETCHED,
    payload: queryParams
})

export const sendTransactionComplaint = data => async (dispatch) => {
    try {
        const claimResponse = await api.customer.postTransactionComplaint(data)
        return dispatch(transactionComplaintSent(claimResponse))
    } catch (err) {
        throw err
    }
}

export const fetchQueryParams = queryParams => dispatch => dispatch(queryParamFetched(queryParams))

