import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

import enTranslations from './locales/en/translations.json'
import frTranslations from './locales/fr/translations.json'
import svTranslations from './locales/sv/translations.json'
import plTranslations from './locales/pl/translations.json'
import esTranslations from './locales/es/translations.json'
import caTranslations from './locales/ca/translations.json'
import csTranslations from './locales/cs/translations.json'


i18n
.use(LanguageDetector)
.use(reactI18nextModule)
.init({
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    debug: true,

    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    react: {
        wait: true,
        defaultTransParent: 'span' // the parent surrounding <Trans> component (needed as we're using React < 16)
    }
})
i18n.addResourceBundle('en', 'translations', enTranslations, true, true)
i18n.addResourceBundle('fr', 'translations', frTranslations, true, true)
i18n.addResourceBundle('sv', 'translations', svTranslations, true, true)
i18n.addResourceBundle('pl', 'translations', plTranslations, true, true)
i18n.addResourceBundle('es', 'translations', esTranslations, true, true)
i18n.addResourceBundle('ca', 'translations', caTranslations, true, true)
i18n.addResourceBundle('cs', 'translations', csTranslations, true, true)


export default i18n
